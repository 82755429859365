<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">구매요청</h2>
      </div>
      <div class="body_section">
        <PurchaseListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :defaultStatusId="defaultStatusId"
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button
              v-if="isShowAccessBtn(menuId.PURCHASE_WRITE)"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickGoToWrite"
            >
              <span class="ico_purchase ico_cross_w" />신규 등록
            </button>
          </template>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :isHorizontalScroll="true"
          :scrollWidth="'1600px'"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 접수 상태 -->
            <col style="width: 80px" />
            <!-- 전자결재 -->
            <col style="width: 60px" />
            <!-- 구매요청등록일 -->
            <col style="width: 80px" />
            <!-- 접수완료일 -->
            <col style="width: 80px" />
            <!-- 구매요청번호 -->
            <col style="width: 110px" />
            <!-- 구매요청명 -->
            <col />
            <!-- 구매요청금액 -->
            <col style="width: 120px" />
            <!-- 프로젝트코드 -->
            <!-- <col style="width: 140px" /> -->
            <!-- 프로젝트명 -->
            <col style="width: 220px" />
            <!-- 품목코드 -->
            <col style="width: 120px" />
            <!-- 품목명 -->
            <col style="width: 120px" />
            <!-- 구매요청자 -->
            <col style="width: 160px" />
            <!-- 구매담당자 -->
            <col style="width: 180px" />
          </template>
          <template v-slot:tr>
            <th>접수상태</th>
            <th>전자결재</th>
            <th>등록일</th>
            <th>접수완료일</th>
            <th>구매요청번호</th>
            <th>구매요청명</th>
            <th>구매요청금액</th>
            <!-- <th>프로젝트코드</th> -->
            <th>프로젝트명</th>
            <th>품목코드</th>
            <th>품목명</th>
            <th>구매요청자</th>
            <th>구매담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <PurchaseListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="12" class="td_empty">구매요청 내역이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import { mapState } from "vuex";
import PageWithLayout from "@/components/layout/PageWithLayout";
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";

import PurchaseListFilterBar from "@/components/admin/purchase/list/PurchaseListFilterBar";
import PurchaseListLine from "@/components/admin/purchase/list/PurchaseListLine";

import ApiService from "@/services/ApiService";

import { PURCHASE_LIST_ACTION } from "@/store/modules/purchase/action";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { downloadFile } from "@/utils/fileUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";
import { stringToDate } from "@/utils/dateUtils";

export default {
  name: "PurchaseList",
  components: {
    PageWithLayout,
    ListPage,
    TableHead,
    SelectBoxPageRange,
    PurchaseListFilterBar,
    PurchaseListLine,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.ST_PURCHASE_REQUEST,

      defaultStatusId: "",
      defaultKeywordSelectedId: "projectName",
    };
  },

  computed: {
    ...mapState({
      statusList: (state) => state.purchase.statusList,
      keywordList: (state) => state.purchase.keywordList,
    }),
  },
  created() {
    const params = this.checkUrlQuery();
    params.status = this.defaultStatusId;
    this.$store.dispatch(PURCHASE_LIST_ACTION).then(() => {
      this.getDataInit(params);
    });
  },

  methods: {
    onClickGoToWrite() {
      this.$router.push({
        path: this.$routerPath.PURCHASE_WRITE,
      });
    },

    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordList);

      const objStatus = this.checkQueryStatus(query);

      // 날짜 검색이 좀 다름.
      const objDates = this.checkQueryDates(query, "startDate", "endDate");

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus);
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status, dateSelectedId } =
        objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }

      if (status) {
        params.status = status;
      }

      return params;
    },
    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/purchase`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) return;
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: "arraybuffer",
      });

      this.$store.dispatch(PROGRESS_END_ACTION);

      downloadFile(result, `구매요청_${this.moment().format("YYYYMMDD")}.xlsx`);
    },
  },
};
</script>
