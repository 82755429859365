<template>
  <ListRow>
    <td>
      <span :class="['badge_state', rowData.status === 'C' ? 'disabled' : '']">{{
        rowData.statusCodeName
      }}</span>
    </td>
    <td>
      <template v-if="rowData.docStatus">
        <button
          class="link_subject align_center"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.docNo}`)"
        >
          {{ rowData.docStatusName }}
        </button>
        <!-- <button
          v-if="isMyPayment"
          class="link_subject align_center"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.docNo}`)"
        >
          {{ rowData.docStatusName }}
        </button>
        <button v-else class="align_center" @click="onClickPaymentReject">
          {{ rowData.docStatusName }}
        </button> -->
      </template>
      <template v-else>
        {{ "-" }}
      </template>
    </td>
    <td>{{ rowData.regDate | dateStringFormat }}</td>
    <td>{{ rowData.statusCompleteDate | dateStringFormat }}</td>
    <td>{{ rowData.requestCid }}</td>
    <td class="td_ellip" :data-ellip="rowData.title">
      <router-link
        :class="['link_subject']"
        :to="toPath"
        :data-ellip="rowData.title"
        v-html="rowData.title"
      />
      <!-- <a
        :class="['link_subject']"
        @click="onClickDetailLink(toPath)"
        :data-ellip="rowData.title"
        v-html="rowData.title"
      ></a> -->
    </td>
    <td class="align_right">
      {{ rowData.totalRequestPrice | currency | nullToDash }}
    </td>
    <!-- <td class="align_left">
      {{ rowData.projectCode }}
    </td> -->
    <td
      class="td_ellip align_left"
      :data-ellip="`[${rowData.projectCode}] ${rowData.projectName}`"
      v-html="rowData.projectName"
    ></td>
    <td class="align_left">
      <template v-if="computedDisplayCode !== '-'">
        <button
          :class="['link_subject']"
          @click="$windowOpen(`${$routerPath.ITEM_VIEW}/${rowData.purchaseGoodsList[0].itemCode}`)"
          v-html="computedDisplayCode"
        ></button>
      </template>
      <template v-else> - </template>
    </td>
    <td class="align_left" v-html="computedItemName" />
    <td>{{ `${rowData.requesterName}(${rowData.requesterId})` }}</td>
    <td v-html="computedBuyer" />
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";
import LocalStorageManager from "@/LocalStorageManager";

import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "PurchaseListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const { status, requestCid } = this.rowData;
      const path = this.$routerPath.PURCHASE_VIEW;
      const ret = makePathWithQuery(path, requestCid, this.$route.query);

      return ret;
    },
    //
    computedDisplayCode() {
      const { purchaseGoodsList } = this.rowData;
      if (!purchaseGoodsList) {
        return "-";
      }
      const { displayCode } = this.rowData.purchaseGoodsList[0];
      let ret = "";
      if (!displayCode) {
        ret = "-";
      } else {
        const categoryIdList = purchaseGoodsList.reduce((acc, ele, index) => {
          const { displayCode } = ele;
          if (displayCode) {
            acc.push(displayCode);
          }
          return acc;
        }, []);
        const dropList = _.drop(categoryIdList);
        if (dropList.length > 0) {
          ret = `${displayCode} <strong data-ellip="${dropList.join("\n")}">(${
            dropList.length
          })</strong>`;
        } else {
          ret = `${displayCode}`;
        }
      }
      return ret;
    },
    computedItemName() {
      const { purchaseGoodsList } = this.rowData;
      if (!purchaseGoodsList) {
        return "-";
      }
      const { itemName } = this.rowData.purchaseGoodsList[0];
      let ret = "";
      if (!itemName) {
        ret = "-";
      } else {
        const itemNameList = purchaseGoodsList.reduce((acc, ele, index) => {
          const { itemName } = ele;
          if (itemName) {
            acc.push(itemName);
          }
          return acc;
        }, []);

        const dropList = _.drop(itemNameList);
        if (dropList.length > 0) {
          ret = `${itemName} <strong data-ellip="${dropList.join("\n")}">(${
            dropList.length
          })</strong>`;
        } else {
          ret = `${itemName}`;
        }
      }
      return ret;
    },
    computedBuyer() {
      const { buyerList } = this.rowData;
      let ret = "";
      const [buyerFirst, ...restBuyerList] = buyerList;
      if (buyerList.length > 1) {
        const restBuyer = restBuyerList
          .map((item) => {
            const { loginId, name } = item;
            return `${name}(${loginId})`;
          })
          .join("\n");
        ret = `${buyerFirst.name}(${buyerFirst.loginId}) <strong data-ellip="${restBuyer}">외 ${restBuyerList.length}명</strong>`;
      } else {
        ret = `${buyerFirst.name}(${buyerFirst.loginId})`;
      }
      return ret;
    },
    isMyPayment() {
      const { username } = LocalStorageManager.shared.getUserData();

      const { requesterId } = this.rowData;
      return username === requesterId;
    },
    computedPaymentPath() {
      const { docStatus } = this.rowData;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
  },
  methods: {
    onClickItemDetailOpen(rowData) {
      const path = `${this.$routerPath.ITEM_VIEW}/${rowData.categoryId}`;
      this.$windowOpen(path);
    },
    onClickPaymentReject() {
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: "해당 문서의 열람 권한이 없습니다.",
      });
    },
  },
};
</script>
